function isInViewport(el) {
  var rect = el.getBoundingClientRect();
  var windowHeight = window.innerHeight || document.documentElement.clientHeight;
  var windowWidth = window.innerWidth || document.documentElement.clientWidth;
  var vertInView = rect.top <= windowHeight && rect.top + rect.height >= 0;
  var horInView = rect.left <= windowWidth && rect.left + rect.width >= 0;
  return vertInView && horInView;
}
function mouseDistanceFromElement(mouseEvent, element) {
  var $n = element,
    mX = mouseEvent.pageX,
    mY = mouseEvent.pageY,
    from = {
      x: mX,
      y: mY
    },
    off = $n.getBoundingClientRect(),
    ny1 = off.top + document.documentElement.scrollTop,
    ny2 = ny1 + $n.offsetHeight,
    nx1 = off.left + document.documentElement.scrollLeft,
    nx2 = nx1 + $n.offsetWidth,
    maxX1 = Math.max(mX, nx1),
    minX2 = Math.min(mX, nx2),
    maxY1 = Math.max(mY, ny1),
    minY2 = Math.min(mY, ny2),
    intersectX = minX2 >= maxX1,
    intersectY = minY2 >= maxY1,
    to = {
      x: intersectX ? mX : nx2 < mX ? nx2 : nx1,
      y: intersectY ? mY : ny2 < mY ? ny2 : ny1
    },
    distX = to.x - from.x,
    distY = to.y - from.y,
    hypot = Math.pow(Math.pow(distX, 2) + Math.pow(distY, 2), 1 / 2);
  return Math.floor(hypot);
}

export { isInViewport, mouseDistanceFromElement };
