import { wrap } from 'i18next-subliminal';
import { api } from './postMessage.js';
import { store } from '../store.js';

function setValueOnNode(meta, value) {
  var item = store.get(meta.eleUniqueID);
  if (!item || !item.keys[meta.textType]) return;
  var txtWithHiddenMeta = wrap(value, item.subliminal);
  if (meta.textType === 'text') {
    item.node.textContent = txtWithHiddenMeta;
  } else if (meta.textType.indexOf('attr:') === 0) {
    var attr = meta.textType.replace('attr:', '');
    item.node.setAttribute(attr, txtWithHiddenMeta);
  } else if (meta.textType === 'html') {
    var id = "".concat(meta.textType, "-").concat(meta.children);
    if (!item.originalChildNodes) {
      var clones = [];
      item.node.childNodes.forEach(function (c) {
        clones.push(c);
      });
      item.originalChildNodes = clones;
    }
    if (item.children[id].length === item.node.childNodes.length) {
      item.node.innerHTML = txtWithHiddenMeta;
    } else {
      var children = item.children[id];
      var first = children[0].child;
      var dummy = document.createElement('div');
      dummy.innerHTML = txtWithHiddenMeta;
      var nodes = [];
      dummy.childNodes.forEach(function (c) {
        nodes.push(c);
      });
      nodes.forEach(function (c) {
        try {
          item.node.insertBefore(c, first);
        } catch (error) {
          item.node.appendChild(c);
        }
      });
      children.forEach(function (replaceable) {
        if (item.node.contains(replaceable.child)) item.node.removeChild(replaceable.child);
      });
    }
  }
}
function handler(payload) {
  var meta = payload.meta,
    value = payload.value;
  if (meta && value !== undefined) {
    setValueOnNode(meta, value);
  }
}
api.addHandler('editKey', handler);

export { setValueOnNode };
