import { api } from './postMessage.js';
import { store } from '../store.js';
import { resetHighlight, selectedHighlight } from '../ui/highlightNode.js';

var previousMatches = [];
function handler(payload) {
  var keys = payload.keys;
  var matchingItems = [];
  Object.values(store.data).forEach(function (item) {
    var matches = Object.values(item.keys).filter(function (k) {
      return keys.includes(k.qualifiedKey);
    });
    if (matches.length) {
      matchingItems.push(item);
    }
  });
  previousMatches.forEach(function (item) {
    resetHighlight(item, item.node, item.keys, false);
  });
  matchingItems.forEach(function (item) {
    selectedHighlight(item, item.node, item.keys);
  });
  previousMatches = matchingItems;
}
api.addHandler('selectedKeys', handler);
