import { stopMouseTracking, startMouseTracking } from './mouseDistance.js';

function initDragElement() {
  var pos1 = 0;
  var pos2 = 0;
  var pos3 = 0;
  var pos4 = 0;
  var popups = document.getElementsByClassName('i18next-editor-popup');
  var elmnt = null;
  var overlay = null;
  var currentZIndex = 100;
  for (var i = 0; i < popups.length; i++) {
    var popup = popups[i];
    var header = getHeader(popup);
    popup.onmousedown = function () {
      this.style.zIndex = '' + ++currentZIndex;
    };
    if (header) {
      header.parentPopup = popup;
      header.onmousedown = dragMouseDown;
    }
  }
  function dragMouseDown(e) {
    if (!overlay) overlay = document.getElementById('i18next-editor-popup-overlay');
    if (overlay) overlay.style.display = 'block';
    stopMouseTracking();
    elmnt = this.parentPopup;
    elmnt.style.zIndex = '' + ++currentZIndex;
    e = e || window.event;
    pos3 = e.clientX;
    pos4 = e.clientY;
    document.onmouseup = closeDragElement;
    document.onmousemove = elementDrag;
  }
  function elementDrag(e) {
    if (!elmnt) {
      return;
    }
    e = e || window.event;
    pos1 = pos3 - e.clientX;
    pos2 = pos4 - e.clientY;
    pos3 = e.clientX;
    pos4 = e.clientY;
    elmnt.style.top = elmnt.offsetTop - pos2 + 'px';
    elmnt.style.left = elmnt.offsetLeft - pos1 + 'px';
  }
  function closeDragElement() {
    startMouseTracking();
    if (overlay) overlay.style.display = 'none';
    document.onmouseup = null;
    document.onmousemove = null;
  }
  function getHeader(element) {
    var headerItems = element.getElementsByClassName('i18next-editor-popup-header');
    if (headerItems.length === 1) {
      return headerItems[0];
    }
    return null;
  }
}
function initResizeElement() {
  var popups = document.getElementsByClassName('i18next-editor-popup');
  var element = null;
  var overlay = null;
  var startX, startY, startWidth, startHeight;
  for (var i = 0; i < popups.length; i++) {
    var p = popups[i];
    var right = document.createElement('div');
    right.className = 'resizer-right';
    p.appendChild(right);
    right.addEventListener('mousedown', initDrag, false);
    right.parentPopup = p;
    var bottom = document.createElement('div');
    bottom.className = 'resizer-bottom';
    p.appendChild(bottom);
    bottom.addEventListener('mousedown', initDrag, false);
    bottom.parentPopup = p;
    var both = document.createElement('div');
    both.className = 'resizer-both';
    p.appendChild(both);
    both.addEventListener('mousedown', initDrag, false);
    both.parentPopup = p;
  }
  function initDrag(e) {
    stopMouseTracking();
    if (!overlay) overlay = document.getElementById('i18next-editor-popup-overlay');
    if (overlay) overlay.style.display = 'block';
    element = this.parentPopup;
    startX = e.clientX;
    startY = e.clientY;
    startWidth = parseInt(document.defaultView.getComputedStyle(element).width, 10);
    startHeight = parseInt(document.defaultView.getComputedStyle(element).height, 10);
    document.documentElement.addEventListener('mousemove', doDrag, false);
    document.documentElement.addEventListener('mouseup', stopDrag, false);
  }
  function doDrag(e) {
    element.style.width = startWidth + e.clientX - startX + 'px';
    element.style.height = startHeight + e.clientY - startY + 'px';
  }
  function stopDrag() {
    startMouseTracking();
    if (overlay) overlay.style.display = 'none';
    document.documentElement.removeEventListener('mousemove', doDrag, false);
    document.documentElement.removeEventListener('mouseup', stopDrag, false);
  }
}

export { initDragElement, initResizeElement };
