import { locizePlugin, locizeEditorPlugin } from './locizePlugin.js';
export { locizeEditorPlugin, locizePlugin } from './locizePlugin.js';
import { startStandalone } from './startStandalone.js';
export { startStandalone } from './startStandalone.js';
import './api/handleEditKey.js';
import './api/handleCommitKey.js';
import './api/handleCommitKeys.js';
import './api/handleConfirmInitialized.js';
import './api/handleRequestPopupChanges.js';
import './api/handleRequestResourceBundle.js';
import './api/handleSelectedKeys.js';
import './api/handleIsLocizeEnabled.js';
import './api/handleTurnOn.js';
import './api/handleTurnOff.js';
import { addLocizeSavedHandler, turnOn, turnOff, setEditorLng } from './api/postMessage.js';
export { addLocizeSavedHandler, setEditorLng, turnOff, turnOn } from './api/postMessage.js';
import { wrap, unwrap, containsHiddenMeta, PostProcessor } from 'i18next-subliminal';
export { PostProcessor, containsHiddenMeta, unwrap, wrap } from 'i18next-subliminal';

var index = {
  wrap: wrap,
  unwrap: unwrap,
  containsHiddenMeta: containsHiddenMeta,
  PostProcessor: PostProcessor,
  addLocizeSavedHandler: addLocizeSavedHandler,
  locizePlugin: locizePlugin,
  locizeEditorPlugin: locizeEditorPlugin,
  turnOn: turnOn,
  turnOff: turnOff,
  setEditorLng: setEditorLng,
  startStandalone: startStandalone
};

export { index as default };
