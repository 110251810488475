import _defineProperty from '@babel/runtime/helpers/esm/defineProperty';
import { parseTree, setImplementation } from './parser.js';
import { createObserver } from './observer.js';
import { startMouseTracking } from './ui/mouseDistance.js';
import { initDragElement, initResizeElement } from './ui/popup.js';
import { popupId, Popup } from './ui/elements/popup.js';
import { getIframeUrl } from './vars.js';
import './api/handleEditKey.js';
import './api/handleCommitKey.js';
import './api/handleCommitKeys.js';
import './api/handleConfirmInitialized.js';
import './api/handleRequestPopupChanges.js';
import './api/handleRequestResourceBundle.js';
import './api/handleSelectedKeys.js';
import './api/handleIsLocizeEnabled.js';
import './api/handleTurnOn.js';
import './api/handleTurnOff.js';
import { api } from './api/postMessage.js';

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function start() {
  var implementation = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  if (typeof document === 'undefined') return;
  var scriptEle = document.getElementById('locize');
  var config = {};
  ['projectId', 'version'].forEach(function (attr) {
    if (!scriptEle) return;
    var value = scriptEle.getAttribute(attr.toLowerCase()) || scriptEle.getAttribute('data-' + attr.toLowerCase());
    if (value === 'true') value = true;
    if (value === 'false') value = false;
    if (value !== undefined && value !== null) config[attr] = value;
  });
  config = _objectSpread(_objectSpread({}, implementation.getLocizeDetails()), config);
  api.init(implementation);
  setImplementation(implementation);
  implementation === null || implementation === void 0 || implementation.bindLanguageChange(function (lng) {
    api.sendCurrentTargetLanguage(implementation.getLng());
  });
  function continueToStart() {
    var observer = createObserver(document.body, function (eles) {
      eles.forEach(function (ele) {
        parseTree(ele);
      });
      api.sendCurrentParsedContent();
    });
    observer.start();
    startMouseTracking(observer);
    if (!document.getElementById(popupId)) {
      document.body.append(Popup(getIframeUrl(), function () {
        api.requestInitialize(config);
      }));
      initDragElement();
      initResizeElement();
    }
  }
  if (document.body) return continueToStart();
  window.addEventListener('load', function () {
    return continueToStart();
  });
}

export { start };
