import _defineProperty from '@babel/runtime/helpers/esm/defineProperty';
import { containsHiddenMeta, containsHiddenStartMarker, unwrap } from 'i18next-subliminal';
import { store } from './store.js';
import { uninstrumentedStore } from './uninstrumentedStore.js';
import { validAttributes } from './vars.js';
import './shims/uniqueID.js';

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var currentSourceLng;
var i18n;
var ignoreMergedEleUniqueIds = [];
function setImplementation(impl) {
  i18n = impl;
}
function walk(node, func) {
  if (node.dataset && node.dataset.i18nextEditorElement === 'true') return;
  func(node);
  var children = node.childNodes;
  for (var i = 0; i < children.length; i++) {
    walk(children[i], func);
  }
}
function extractMeta(id, type, meta, children) {
  var _i18n, _i18n2;
  var invisibleMeta = meta.invisibleMeta,
    text = meta.text;
  if (!invisibleMeta || !invisibleMeta.key || !invisibleMeta.ns) return;
  if (!currentSourceLng) currentSourceLng = (_i18n = i18n) === null || _i18n === void 0 ? void 0 : _i18n.getSourceLng();
  return _objectSpread(_objectSpread({
    eleUniqueID: id,
    textType: type,
    children: children ? children.map(function (c) {
      return c.childIndex;
    }).join(',') : null,
    qualifiedKey: "".concat(invisibleMeta.ns, ":").concat(invisibleMeta.key)
  }, invisibleMeta), {}, {
    extractedText: text,
    i18nTargetLng: (_i18n2 = i18n) === null || _i18n2 === void 0 ? void 0 : _i18n2.getLng(),
    i18nSourceLng: currentSourceLng,
    i18nRawText: _defineProperty(_defineProperty({}, "".concat(invisibleMeta.lng), invisibleMeta.source === 'translation' && i18n ? i18n.getResource(invisibleMeta.lng, invisibleMeta.ns, invisibleMeta.key) : null), "".concat(currentSourceLng), invisibleMeta.source === 'translation' && i18n ? i18n.getResource(currentSourceLng, invisibleMeta.ns, invisibleMeta.key) : null)
  });
}
function containsOnlySpaces(str) {
  return /^\s*$/.test(str);
}
function handleNode(node) {
  if (node.childNodes && !ignoreMergedEleUniqueIds.includes(node.uniqueID)) {
    var merge = [];
    node.childNodes.forEach(function (child, i) {
      if (merge.length && child.nodeName !== '#text') {
        ignoreMergedEleUniqueIds.push(child.uniqueID);
        merge.push({
          childIndex: i,
          child: child
        });
      }
      if (child.nodeName !== '#text') return;
      var txt = child.textContent;
      if (containsOnlySpaces(txt)) return;
      var hasHiddenMeta = containsHiddenMeta(txt);
      var hasHiddenStartMarker = containsHiddenStartMarker(txt);
      if (hasHiddenStartMarker && hasHiddenMeta) {
        var meta = unwrap(txt);
        store.save(node.uniqueID, meta.invisibleMeta, 'text', extractMeta(node.uniqueID, 'text', meta), node);
      } else if (hasHiddenStartMarker) {
        merge.push({
          childIndex: i,
          child: child,
          text: txt
        });
      } else if (merge.length && !hasHiddenMeta) {
        merge.push({
          childIndex: i,
          child: child,
          text: txt
        });
      } else if (merge.length && hasHiddenMeta) {
        merge.push({
          childIndex: i,
          child: child,
          text: txt
        });
        var _meta = unwrap(merge.reduce(function (mem, item) {
          return mem + item.text;
        }, ''));
        store.save(node.uniqueID, _meta.invisibleMeta, 'html', extractMeta(node.uniqueID, 'html', _meta, merge), node, merge);
        merge = [];
      } else if (txt) {
        uninstrumentedStore.save(node.uniqueID, 'text', node);
      }
    });
  }
  if (!node.getAttribute) return;
  validAttributes.forEach(function (attr) {
    var txt = node.getAttribute(attr);
    if (containsHiddenMeta(txt)) {
      var meta = unwrap(txt);
      store.save(node.uniqueID, meta.invisibleMeta, "attr:".concat(attr), extractMeta(node.uniqueID, "attr:".concat(attr), meta), node);
    } else if (txt) {
      uninstrumentedStore.save(node.uniqueID, "attr:".concat(attr), node);
    }
  });
}
function parseTree(node) {
  currentSourceLng = undefined;
  walk(node, handleNode);
  store.clean();
  ignoreMergedEleUniqueIds = [];
  return store.data;
}

export { parseTree, setImplementation };
