import { sheet } from '../stylesheet.js';
import { stopMouseTracking, startMouseTracking } from '../mouseDistance.js';
import { minimizeIconUrl, locizeIconUrl } from './icons.js';

if (sheet) {
  sheet.insertRule("@keyframes i18next-editor-animate-top { \n      from {\n        top: calc(100vh + 600px); \n        left: calc(100vw + 300px);\n        opacity: 0;\n      }\n      to {\n        top: var(--i18next-editor-popup-position-top);\n        left: var(--i18next-editor-popup-position-left);\n        opacity: 1;\n      }\n    }");
  sheet.insertRule("@keyframes i18next-editor-animate-bottom { \n      from {\n        top: var(--i18next-editor-popup-position-top);\n        left: var(--i18next-editor-popup-position-left);\n        opacity: 1;\n      }\n      to {\n        top: calc(100vh + 600px); \n        left: calc(100vw + 300px);\n        opacity: 0;\n      }\n    }");
  sheet.insertRule(".i18next-editor-popup * { \n      -webkit-touch-callout: none; /* iOS Safari */\n      -webkit-user-select: none; /* Safari */\n      -khtml-user-select: none; /* Konqueror HTML */\n      -moz-user-select: none; /* Firefox */\n      -ms-user-select: none; /* Internet Explorer/Edge */\n      user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */\n    }");
  sheet.insertRule(".i18next-editor-popup .resizer-right {\n      width: 15px;\n      height: 100%;\n      background: transparent;\n      position: absolute;\n      right: -15px;\n      bottom: 0;\n      cursor: e-resize;\n    }");
  sheet.insertRule(".i18next-editor-popup .resizer-both {\n      width: 15px;\n      height: 15px;\n      background: transparent;\n      z-index: 10;\n      position: absolute;\n      right: -15px;\n      bottom: -15px;\n      cursor: se-resize;\n    }");
  sheet.insertRule(".i18next-editor-popup .resizer-bottom {\n      width: 100%;\n      height: 15px;\n      background: transparent;\n      position: absolute;\n      right: 0;\n      bottom: -15px;\n      cursor: s-resize;\n    }");
}
function Ribbon(popupEle, onMaximize) {
  var ribbon = document.createElement('div');
  ribbon.setAttribute('data-i18next-editor-element', 'true');
  ribbon.style = "\n  cursor: pointer;\n  position: fixed;\n  bottom: 25px;\n  right: 25px;\n  display: inline-flex;\n  align-items: center;\n  justify-content: center;\n  width: 50px;\n  height: 50px;\n  background-color:  rgba(249, 249, 249, 0.2);\n  backdrop-filter: blur(3px);\n  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);\n  border-radius: 50%\n  ";
  ribbon.onclick = function () {
    onMaximize();
  };
  var image = document.createElement('img');
  image.src = locizeIconUrl;
  image.style.width = '45px';
  ribbon.appendChild(image);
  return ribbon;
}
function Minimize(popupEle, onMinimize) {
  var image = document.createElement('img');
  image.setAttribute('data-i18next-editor-element', 'true');
  image.src = minimizeIconUrl;
  image.style.width = '24px';
  image.style.cursor = 'pointer';
  image.onclick = function () {
    popupEle.style.setProperty('--i18next-editor-popup-position-top', popupEle.style.top);
    popupEle.style.setProperty('--i18next-editor-popup-position-left', popupEle.style.left);
    popupEle.style.animation = 'i18next-editor-animate-bottom 2s forwards';
    onMinimize();
  };
  return image;
}
var popupId = 'i18next-editor-popup';
function Popup(url, cb) {
  var popup = document.createElement('div');
  popup.setAttribute('id', popupId);
  popup.classList.add('i18next-editor-popup');
  popup.style = "\n  z-index: 9;\n  background-color: transparent;\n  border: 1px solid rgba(200, 200, 200, 0.9);\n  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);\n  border-radius: 3px;\n  --i18next-editor-popup-height: 200px;\n  height: var(--i18next-editor-popup-height);\n  min-height: 150px;\n  min-width: 300px;\n  --i18next-editor-popup-width: 400px;\n  width: var(--i18next-editor-popup-width);\n  max-height: 600px;\n  max-width: 800px;\n\n  position: fixed;\n  --i18next-editor-popup-position-top: calc(100vh - var(--i18next-editor-popup-height) - 10px);\n  top: calc(100vh - var(--i18next-editor-popup-height) - 10px);\n  --i18next-editor-popup-position-left: calc(100vw - var(--i18next-editor-popup-width) - 10px);\n  left: calc(100vw - var(--i18next-editor-popup-width) - 10px);\n\n  overflow: visible;\n  ";
  popup.setAttribute('data-i18next-editor-element', 'true');
  var header = document.createElement('div');
  header.classList.add('i18next-editor-popup-header');
  header.style = "\n  padding: 2px 10px;\n  cursor: move;\n  z-index: 10;\n  backdrop-filter: blur(3px);\n  background-color: rgba(200, 200, 200, 0.5);\n  background: linear-gradient(0deg, rgba(200, 200, 200, 0.6), rgba(200, 200, 200, 0.5));\n  color: #fff;\n  text-align: right;\n  ";
  popup.appendChild(header);
  header.appendChild(Minimize(popup, function () {
    var ribbon = Ribbon(popup, function () {
      popup.style.animation = 'i18next-editor-animate-top 1s';
      startMouseTracking();
      setTimeout(function () {
        document.body.removeChild(ribbon);
      }, 1000);
    });
    document.body.appendChild(ribbon);
    stopMouseTracking();
  }));
  var iframe = document.createElement('iframe');
  iframe.setAttribute('id', 'i18next-editor-iframe');
  iframe.setAttribute('data-i18next-editor-element', 'true');
  iframe.style = "\n    z-index: 100;\n    width: 100%;\n    height: calc(100% - 28px);\n    border: none;\n    background: #fff;\n  ";
  iframe.setAttribute('src', url);
  iframe.addEventListener('load', cb);
  popup.appendChild(iframe);
  var overlay = document.createElement('div');
  overlay.setAttribute('id', 'i18next-editor-popup-overlay');
  overlay.setAttribute('data-i18next-editor-element', 'true');
  overlay.style = "\n  display: none;\n  position: absolute;\n  top: 32px;\n  z-index: 101;\n  width: 100%;\n  height: calc(100% - 32px);\n  background-color: rgba(200, 200, 200, 0.5);\n  background: linear-gradient(0deg, rgba(240, 240, 240, 0.6), rgba(255, 255, 255, 0.5));\n  backdrop-filter: blur(2px);\n";
  popup.appendChild(overlay);
  return popup;
}

export { Popup, popupId };
