var validAttributes = ['placeholder', 'title', 'alt'];
var colors = {
  highlight: '#26a69a',
  warning: '#e67a00'
};
var getIframeUrl = function getIframeUrl() {
  var _prc$env;
  var p;
  if (typeof process !== 'undefined') p = process;
  if (!p && typeof window !== 'undefined') p = window.process;
  var prc = p || {};
  var env = ((_prc$env = prc.env) === null || _prc$env === void 0 ? void 0 : _prc$env.locizeIncontext) || 'production';
  return env === 'development' ? 'http://localhost:3003/' : env === 'staging' ? 'https://incontext-dev.locize.app' : 'https://incontext.locize.app';
};

export { colors, getIframeUrl, validAttributes };
