import _defineProperty from '@babel/runtime/helpers/esm/defineProperty';
import './api/handleEditKey.js';
import './api/handleCommitKey.js';
import './api/handleCommitKeys.js';
import './api/handleConfirmInitialized.js';
import './api/handleRequestPopupChanges.js';
import './api/handleRequestResourceBundle.js';
import './api/handleSelectedKeys.js';
import './api/handleIsLocizeEnabled.js';
import './api/handleTurnOn.js';
import './api/handleTurnOff.js';
import { api, sendMessage } from './api/postMessage.js';
import { createClickHandler } from './clickHandler.js';

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function startLegacy() {
  var implementation = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  if (typeof document === 'undefined') return;
  var scriptEle = document.getElementById('locize');
  var config = {};
  ['projectId', 'version'].forEach(function (attr) {
    if (!scriptEle) return;
    var value = scriptEle.getAttribute(attr.toLowerCase()) || scriptEle.getAttribute('data-' + attr.toLowerCase());
    if (value === 'true') value = true;
    if (value === 'false') value = false;
    if (value !== undefined && value !== null) config[attr] = value;
  });
  config = _objectSpread(_objectSpread({}, implementation.getLocizeDetails()), config);
  api.init(implementation, createClickHandler(function (payload) {
    sendMessage('clickedElement', {
      payload: payload
    });
  }, implementation.getLocizeDetails()));
  api.sendCurrentTargetLanguage = function (lng) {
    sendMessage('setLng', {
      lng: lng || implementation.getLng()
    });
  };
  if (typeof window !== 'undefined') {
    var oldHref = window.document.location.href;
    window.addEventListener('load', function () {
      sendMessage('hrefChanged', {
        href: window.document.location.href
      });
      var bodyList = window.document.querySelector('body');
      var observer = new window.MutationObserver(function (mutations) {
        mutations.forEach(function (mutation) {
          if (oldHref !== window.document.location.href) {
            oldHref = window.document.location.href;
            sendMessage('hrefChanged', {
              href: oldHref
            });
          }
        });
      });
      var config = {
        childList: true,
        subtree: true
      };
      observer.observe(bodyList, config);
    });
  }
  implementation === null || implementation === void 0 || implementation.bindLanguageChange(function (lng) {
    api.sendCurrentTargetLanguage(implementation.getLng());
  });
  implementation === null || implementation === void 0 || implementation.bindMissingKeyHandler(function (lng, ns, k, val) {
    api.onAddedKey(lng, ns, k, val);
  });
}

export { startLegacy };
