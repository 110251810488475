"use strict";

exports.__esModule = true;
exports["default"] = void 0;
/**
 * Reduces hex values if possible e.g. #ff8866 to #f86
 * @private
 */
var reduceHexValue = function reduceHexValue(value) {
  if (value.length === 7 && value[1] === value[2] && value[3] === value[4] && value[5] === value[6]) {
    return "#" + value[1] + value[3] + value[5];
  }
  return value;
};
var _default = exports["default"] = reduceHexValue;
module.exports = exports.default;