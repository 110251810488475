import _defineProperty from '@babel/runtime/helpers/esm/defineProperty';
import { wrap } from './encoder.js';

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var postProcessorName = 'subliminal';
var SubliminalPostProcessor = {
  name: postProcessorName,
  type: 'postProcessor',
  options: {},
  setOptions: function setOptions(options) {
    this.options = _objectSpread(_objectSpread({}, options), this.options);
  },
  process: function process(value, keyIn, options, translator) {
    var opt = this.options = _objectSpread(_objectSpread({}, options), this.options);
    var key, ns, lng, source;
    if (options.i18nResolved) {
      key = options.i18nResolved.exactUsedKey;
      ns = options.i18nResolved.usedNS;
      lng = options.i18nResolved.usedLng;
      if (options.i18nResolved.res === undefined) {
        if (key !== value) {
          source = 'default';
        } else {
          source = 'key';
        }
      } else {
        source = 'translation';
      }
    } else {
      var _ref, _opt$keySeparator, _translator$options, _ref2, _namespaces$, _translator$options2;
      var keySeparator = (_ref = (_opt$keySeparator = opt.keySeparator) !== null && _opt$keySeparator !== void 0 ? _opt$keySeparator : translator === null || translator === void 0 || (_translator$options = translator.options) === null || _translator$options === void 0 ? void 0 : _translator$options.keySeparator) !== null && _ref !== void 0 ? _ref : '.';
      var _translator$extractFr = translator.extractFromKey(keyIn.join(keySeparator), options),
        extractedKey = _translator$extractFr.key,
        namespaces = _translator$extractFr.namespaces;
      key = extractedKey;
      ns = (_ref2 = (_namespaces$ = namespaces === null || namespaces === void 0 ? void 0 : namespaces[0]) !== null && _namespaces$ !== void 0 ? _namespaces$ : opt.ns) !== null && _ref2 !== void 0 ? _ref2 : translator === null || translator === void 0 || (_translator$options2 = translator.options) === null || _translator$options2 === void 0 ? void 0 : _translator$options2.defaultNS;
      lng = options.lng || this.language;
      if (key === value) {
        source = 'key';
      } else {
        source = 'translation';
      }
    }
    return wrap(value, {
      key: key,
      ns: ns,
      lng: lng,
      source: source
    });
  },
  overloadTranslationOptionHandler: function overloadTranslationOptionHandler() {
    return {
      postProcess: postProcessorName,
      postProcessPassResolved: true
    };
  }
};

export { SubliminalPostProcessor as default };
