import { defaults, replaceIn, debounce, isMissingOption } from './utils.js';
import request from './request.js';
var getDefaults = function getDefaults() {
  return {
    lastUsedPath: 'https://api.locize.app/used/{{projectId}}/{{version}}/{{lng}}/{{ns}}',
    referenceLng: 'en',
    crossDomain: true,
    setContentTypeJSON: false,
    version: 'latest',
    debounceSubmit: 90000,
    allowedHosts: ['localhost']
  };
};
var locizeLastUsed = {
  init: function init(options) {
    var isI18next = options.t && typeof options.t === 'function';
    if (isI18next && !options.options.locizeLastUsed.projectId && options.options.backend.projectId) {
      options.options.locizeLastUsed.projectId = options.options.backend.projectId;
    }
    if (isI18next && !options.options.locizeLastUsed.version && options.options.backend.version) {
      options.options.locizeLastUsed.version = options.options.backend.version;
    }
    if (isI18next && !options.options.locizeLastUsed.apiKey && options.options.backend.apiKey) {
      options.options.locizeLastUsed.apiKey = options.options.backend.apiKey;
    }
    if (isI18next && !options.options.locizeLastUsed.referenceLng && options.options.backend.referenceLng) {
      options.options.locizeLastUsed.referenceLng = options.options.backend.referenceLng;
    }
    if (isI18next && !options.options.locizeLastUsed.referenceLng && options.options.fallbackLng && Array.isArray(options.options.fallbackLng) && options.options.fallbackLng[0] !== 'dev') {
      options.options.locizeLastUsed.referenceLng = options.options.fallbackLng[0];
    }
    this.options = isI18next ? defaults(options.options.locizeLastUsed, this.options || {}, getDefaults()) : defaults(options, this.options || {}, getDefaults());
    var hostname = typeof window !== 'undefined' && window.location && window.location.hostname;
    if (hostname) {
      this.isAllowed = this.options.allowedHosts.indexOf(hostname) > -1;
    } else {
      this.isAllowed = true;
    }
    this.submitting = null;
    this.pending = {};
    this.done = {};
    this.submit = debounce(this.submit, this.options.debounceSubmit);
    if (isI18next) this.interceptI18next(options);
  },
  interceptI18next: function interceptI18next(i18next) {
    var _this = this;
    var origGetResource = i18next.services.resourceStore.getResource;
    i18next.services.resourceStore.getResource = function (lng, ns, key, options) {
      if (key) _this.used(ns, key);
      return origGetResource.call(i18next.services.resourceStore, lng, ns, key, options);
    };
  },
  used: function used(ns, key, callback) {
    var _this2 = this;
    ['pending', 'done'].forEach(function (k) {
      if (_this2.done[ns] && _this2.done[ns][key]) return;
      if (!_this2[k][ns]) _this2[k][ns] = {};
      _this2[k][ns][key] = true;
    });
    this.submit(callback);
  },
  submit: function submit(callback) {
    var _this3 = this;
    if (!this.isAllowed) return callback && callback(new Error('not allowed'));
    if (this.submitting) return this.submit(callback);
    var isMissing = isMissingOption(this.options, ['projectId', 'version', 'apiKey', 'referenceLng']);
    if (isMissing) return callback && callback(new Error(isMissing));
    this.submitting = this.pending;
    this.pending = {};
    var namespaces = Object.keys(this.submitting);
    var todo = namespaces.length;
    var doneOne = function doneOne(err) {
      todo--;
      if (!todo) {
        _this3.submitting = null;
        if (callback) callback(err);
      }
    };
    namespaces.forEach(function (ns) {
      var keys = Object.keys(_this3.submitting[ns]);
      var url = replaceIn(_this3.options.lastUsedPath, ['projectId', 'version', 'lng', 'ns'], defaults({
        lng: _this3.options.referenceLng,
        ns: ns
      }, _this3.options));
      if (keys.length) {
        request(defaults({
          authorize: true
        }, _this3.options), url, keys, doneOne);
      } else {
        doneOne();
      }
    });
  }
};
locizeLastUsed.type = '3rdParty';
export default locizeLastUsed;