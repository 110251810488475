import { store } from '../store.js';
import { uninstrumentedStore } from '../uninstrumentedStore.js';
import { isInViewport, mouseDistanceFromElement } from './utils.js';
import { debounce } from '../utils.js';
import { highlight, resetHighlight, highlightUninstrumented } from './highlightNode.js';

var debouncedUpdateDistance = debounce(function (e, observer) {
  Object.values(store.data).forEach(function (item) {
    if (!isInViewport(item.node)) return;
    var distance = mouseDistanceFromElement(e, item.node);
    if (distance < 5) {
      highlight(item, item.node, item.keys);
    } else if (distance > 5) {
      var boxDistance = item.ribbonBox ? mouseDistanceFromElement(e, item.ribbonBox) : 1000;
      if (boxDistance > 10) resetHighlight(item, item.node, item.keys);
    }
  });
  Object.values(uninstrumentedStore.data).forEach(function (item) {
    if (!isInViewport(item.node)) return;
    var distance = mouseDistanceFromElement(e, item.node);
    if (distance < 10) {
      highlightUninstrumented(item, item.node, item.keys);
    } else if (distance > 10) {
      resetHighlight(item, item.node, item.keys);
    }
  });
}, 50);
var currentFC;
function startMouseTracking(observer) {
  currentFC = function handle(e) {
    debouncedUpdateDistance(e, observer);
  };
  document.addEventListener('mousemove', currentFC);
}
function stopMouseTracking() {
  document.removeEventListener('mousemove', currentFC);
}

export { startMouseTracking, stopMouseTracking };
