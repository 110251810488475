import { colors } from '../../vars.js';
import { sheet } from '../stylesheet.js';
import '../../api/handleEditKey.js';
import '../../api/handleCommitKey.js';
import '../../api/handleCommitKeys.js';
import '../../api/handleConfirmInitialized.js';
import '../../api/handleRequestPopupChanges.js';
import '../../api/handleRequestResourceBundle.js';
import '../../api/handleSelectedKeys.js';
import '../../api/handleIsLocizeEnabled.js';
import '../../api/handleTurnOn.js';
import '../../api/handleTurnOff.js';
import { api } from '../../api/postMessage.js';
import { RibbonLogo, EditIcon } from './icons.js';

if (sheet) {
  sheet.insertRule('.i18next-editor-button:hover { background-color: rgba(38, 166, 154, 1) !important; }');
}
function RibbonButton(text, attrTitle, onClick) {
  var btn = document.createElement('button');
  btn.style = 'font-family: Arial; position: relative; backdrop-filter: blur(3px); cursor: pointer; padding: 2px 10px 2px 20px; font-size: 15px; font-weight: 300; text-transform: uppercase; color: #fff; background-color: rgba(38, 166, 154, 0.8); border: none; border-radius: 12px';
  btn.classList.add('i18next-editor-button');
  btn.setAttribute('data-i18next-editor-element', 'true');
  btn.setAttribute('title', attrTitle);
  var icon = EditIcon();
  icon.style = 'position: absolute; left: 4px; top: 3px;';
  icon.style.width = '15px';
  btn.appendChild(icon);
  var span = document.createElement('span');
  span.textContent = text;
  btn.appendChild(span);
  btn.onclick = onClick;
  return btn;
}
function RibbonBox() {
  var keys = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var box = document.createElement('div');
  box.style = 'position: absolute; top: 0; left: 0; display: flex; align-items: flex-start; justify-content: center; filter: drop-shadow(0px 0px 20px #aaa );';
  box.setAttribute('data-i18next-editor-element', 'true');
  var arrow = document.createElement('div');
  arrow.style = "\n    position: absolute;\n    width: 0;\n    height: 0;\n    border-top-width: 7px;\n    border-bottom-width: 7px;\n    border-left-width: 10px;\n    border-right-width: 10px;\n    border-style: solid;\n    border-color: transparent ".concat(colors.highlight, " transparent\n      transparent;\n    ");
  box.appendChild(arrow);
  var logo = RibbonLogo();
  box.appendChild(logo);
  var btnbox = document.createElement('div');
  btnbox.style = 'display: flex; flex-direction: column; align-items: flex-start; margin-left: 2px; margin-top: 1px';
  Object.keys(keys).forEach(function (k) {
    var data = keys[k];
    var btn = RibbonButton(k.replace('attr:', ''), "".concat(data.ns, ":").concat(data.key), function () {
      api.selectKey(data);
    });
    btn.style.marginBottom = '2px';
    btnbox.appendChild(btn);
  });
  box.appendChild(btnbox);
  return {
    box: box,
    arrow: arrow
  };
}

export { RibbonBox, RibbonButton };
