import { api } from './postMessage.js';
import { setValueOnNode } from './handleEditKey.js';

function handler(payload) {
  var meta = payload.meta,
    value = payload.value,
    lng = payload.lng;
  if (meta && value !== undefined) {
    setValueOnNode(meta, value);
    var usedLng = lng || api.i18n.getLng();
    api.i18n.setResource(usedLng, meta.ns, meta.key, value);
    api.i18n.triggerRerender();
  }
}
api.addHandler('commitKey', handler);
